
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import Dashboard from '@/components/Dashboard.vue'
import StopSearch from '@/components/StopSearch.vue'
import Modal from '@/components/Modal.vue'
import GenericError from '@/components/GenericError.vue'
import { BusStop } from '@/models/BusStop'
import useBasket from '@/composables/useBasket'
import useSwrv from 'swrv'
import { getAllBusStops } from '@/expressway-api/busStops.api'

export default defineComponent({
  components: { Dashboard, StopSearch, Modal, GenericError },
  setup () {
    const router = useRouter()
    const { showBootMessage, clearBasket } = useBasket()
    const { data: stops, error } = useSwrv('busStops', getAllBusStops)

    const onSelect = (destination: BusStop) => {
      router.push({
        name: 'Journey Search',
        params:
          { props: JSON.stringify({ destination }) }
      })
    }

    const tryAgain = () => {
      clearBasket()
      router.push({ name: 'Journey Search' })
    }
    return {
      tryAgain,
      onSelect,
      showBootMessage,
      clearBasket,
      stops,
      error
    }
  }
})
